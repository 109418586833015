import React from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'
import SEO  from '../components/SEO'

function rawHTMLStaff(props) {
  const { pageContext } = props
  const { pageContent, theme, dealerInfo, headerTags } = pageContext

  // expose employee info to DOM - if it doesn't exist, set it to undefined
  function exposeEmployeeInfo() {
      if (typeof window !== 'undefined') {
          window.employeeInfo = pageContent.Employees.employeeInfo || undefined;
      }
    };
  exposeEmployeeInfo();

  return (
    <Layout>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
      <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags} />
    </Layout>
  )
}
export default rawHTMLStaff